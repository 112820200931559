import Vue from "vue";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import Buttons from "../../Commons/Buttons.vue";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { Paginated, SortingOption, ResultPaginate } from "@/interfaces/paginated";
// @ts-ignore
import { isUndefined, isNull, isEmpty } from "lodash";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
// @ts-ignore
import CustomizeRowObjectGrid from "@/components/Header/Tables/CustomizeRowObjectGrid.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { preparedColumns, preparedData } from "@/views/Admin/ReportsOnDemand/Logic/Utils.ts";
// @ts-ignore
import { preparedColumnDefsGrid } from "@/utils/CustomizeViewData";
// @ts-ignore
import ParamService from "@/services/params-service";
// @ts-ignore
import { mapActions, mapGetters } from "vuex";
import omit from 'lodash/omit';
//@ts-ignore
import { getPermisionReport } from "@/utils/permissionResolve.ts";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
name: "ListReportsOnDemand",
props: {},
components: {
    Buttons,
    Alertize,
    GridTable,
    ActionsTable,
    FilterGridAG,
    CustomizeRowObjectGrid,
    NotPermission
},
data: () => ({
    dataEntity: preparedData(),
    title: "List Reports On Demand",
    context: null,
    selectionRows: "single",
}),
created(){},
mounted(){
    this.$nextTick(async () => {
        await this.getPaginated();
    });
    this.context = { componentParent: this };
},
computed:{

    ...mapGetters("profile", ["getAbility"]),

    getResultPaginate(): ResultPaginate {
        return this.$store.state.report.result_onDemand_paginate;
    },

    getEntities(): any[] {
        const result: ResultPaginate = this.getResultPaginate;
        if (
            isUndefined(result) ||
            isNull(result) ||
            isUndefined(result.data) ||
            isNull(result.data)
        ) {
            return [];
        }
        return result.data;
    },

    getConfigColumnDef(){
        return {
            context: this.context,
            entityName: "Report On Demand",
            eventActions: true,
			flex: 1,
			resizableID: true,
            download: true,
            checkStatus: true,
			minWidthActions: 170,
			maxWidthActions: 170,
            permission: this.getPermission
        };
    },

    getColumnsDef(){
        return preparedColumnDefsGrid(preparedColumns(), this.getConfigColumnDef);
    },

    gerRowsData(){
        if(isEmpty(this.dataEntity.table.items)) return [];
        return this.dataEntity.table.items;
    },

    getCountData() {
		return this.$t("show.reportingOnDemand", {
			total: this.getEntities.length,
		});
    },

    canList(){
        return this.getAbility.can(this.getPermission.actions.index_on_demand, this.getPermission.subject);
    },

    getPermission(){
        return getPermisionReport();
    }
},
methods:{

    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions("report", ["paginatedReportOnDemand", "downloadReportDemand"]),

    updatePaginate(data: any) {
        this.dataEntity.paginated.page = data;
    },

    setFilter(params: { key: string | number; value: any }) {
        this.dataEntity.filters = {};
        this.dataEntity.filters[params.key] =
            typeof params.value !== "undefined" ? params.value : "";
    },

    async getPaginated() {
        if(this.canList){
            this.setLoadingData(TypeLoading.loading)
            await this.paginatedReportOnDemand(
                await ParamService.getParams(
                    this.dataEntity.paginated,
                    this.dataEntity.filters,
                    this.dataEntity.options
                ))
            .then((res) => {
                this.dataEntity.table.items = res.data;
                this.setLoadingData();
            })
            .catch((err) => {this.setLoadingData();});
        }
    },

    async selectedLimit(limit: number) {
        this.dataEntity.paginated.limit = limit;
        this.updatePaginate(1);
        await this.getPaginated();
    },

    async removeOption() {
        this.dataEntity.filters = {};
        this.dataEntity.options = getDefaultSortingOption("id");
        this.updatePaginate(1);
        await this.getPaginated();
    },

    async removeKeyFilter(key: string) {
        const newFilters: any = omit(this.dataEntity.filters, [key]);
        await this.updateParams({
            filters: newFilters,
            options: this.dataEntity.options,
        }, false);
    },

    async selectedOption(params: {
        options: SortingOption;
        filter: string;
    }) {
        this.setFilter({ key: params.options.sort, value: params.filter });
        this.updatePaginate(1);
        await this.updateParams({
            filters: this.dataEntity.filters,
            options: params.options
        });
    },

    async updateParams(params: { filters: any; options: SortingOption;})
    {
        this.dataEntity.filters = params.filters;
        this.dataEntity.options = params.options;
        this.updatePaginate(1);
        await this.getPaginated();
    },

    async downloadReport(params: any){
        this.setLoadingData(TypeLoading.loading)
        await this.downloadReportDemand(params)
        .then((res) => {this.setLoadingData();})
        .catch((err) => {this.setLoadingData();});
    }

},
watch: {
    "dataEntity.paginated.page"(val, old) {
        if (val !== old) {
            this.getPaginated();
        }
    }
}

});
